import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: 20,
        color: "rgb(58 58 58)",
        textAlign: "center",
      }}
    >
      <h1>404 Page Not Found</h1>
    </div>
  );
};

export default NotFound;
